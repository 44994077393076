import React from "react";
import styled from "styled-components";
import Title from "../Components/Title";
import angular from "../img/angular.svg";
import arduino from "../img/arduino.svg";
import bitbucket from "../img/bitbucket.svg";
import blender from "../img/blender.svg";
import css from "../img/css3.svg";
import django from "../img/django.svg";
import docker from "../img/docker.svg";
import flask from "../img/flask.svg";
import git from "../img/git.svg";
import gitHub from "../img/github.svg";
import heroku from "../img/heroku.svg";
import html from "../img/html5.svg";
import mongoDb from "../img/icons8-mongodb.svg";
import mysql from "../img/icons8-mysql-logo.svg";
import java from "../img/java.svg";
import jira from "../img/jira.svg";
import js from "../img/js.svg";
import keras from "../img/keras.svg";
import matPlotLib from "../img/matplotlib.svg";
import nestJs from "../img/nestjs-ar21.svg";
import nodejs from "../img/nodejs.svg";
import numpy from "../img/numpylogo.svg";
import pandas from "../img/pandas.svg";
import python from "../img/python.svg";
import pi from "../img/raspberry-pi.svg";
import react from "../img/reactjs.svg";
import scikitLearn from "../img/scikit-learn.svg";
import ts from "../img/TS.svg";
import { InnerLayout } from "../styles/Layouts";

function Skills() {
  return (
    <SkillsStyled>
      <Title title={"Tech Stack"} />

      <InnerLayout>
        <div>
          <img className="ImageTech" src={python} alt="" title="Python" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={java} alt="" title="Java" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={js} alt="" title="JavaScript" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={ts} alt="" title="TypeScript" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={html} alt="" title="HTML" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={css} alt="" title="CSS" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={angular} alt="" title="Angular" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={react} alt="" title="React" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={flask} alt="" title="Flask" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={django} alt="" title="Django" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={nestJs} alt="" title="Nest JS" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={nodejs} alt="" title="Node JS" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={git} alt="" title="Git" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={gitHub} alt="" title="GitHub" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={bitbucket} alt="" title="Bitbucket" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={mysql} alt="" title="MySQL" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={mongoDb} alt="" title="Mongo DB" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={docker} alt="" title="Docker" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={heroku} alt="" title="Heroku" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={jira} alt="" title="Jira" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={blender} alt="" title="Blender" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={arduino} alt="" title="Arduino" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={pi} alt="" title="Raspberry Pi" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={keras} alt="" title="Keras" />
          &nbsp; &nbsp;
          <img
            className="ImageTech"
            src={matPlotLib}
            alt=""
            title="Matplotlib"
          />
          &nbsp; &nbsp;
          <img
            className="ImageTech"
            src={scikitLearn}
            alt=""
            title="Scikit-learn"
          />
          &nbsp; &nbsp;
          <img className="ImageTech" src={pandas} alt="" title="Pandas" />
          &nbsp; &nbsp;
          <img className="ImageTech" src={numpy} alt="" title="Numpy" />
          &nbsp; &nbsp;
        </div>
      </InnerLayout>
    </SkillsStyled>
  );
}

const SkillsStyled = styled.section`
  .skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 3rem;
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .ImageTech {
    height: 100px;
    width: 100px;
    background-color: #ffffff;
    border-radius: 30%;
  }
`;

export default Skills;
