import blog1 from  '../img/blogs/react_article.jpg';

const blogs = [
    {
        id: 1,
        title: 'Deploying React App with Yarn on Apache Server, CentOS 7',
        date: '01',
        month: 'April',
        image: blog1,
        link: 'https://dev.to/adnanazmee/deploying-react-app-with-yarn-on-apache-server-centos-7-oracle-linux-7-2pp9'
    }
    
]

export default blogs;