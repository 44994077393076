/* eslint-disable no-unused-vars */
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ConstructionRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useLocation, Route, Switch as Switching } from "react-router";
import styled from "styled-components";
import Sidebar from "./Components/Sidebar";
import AboutPage from "./Pages/AboutPage";
import BlogsPage from "./Pages/BlogsPage";
import ContactPage from "./Pages/ContactPage";
import HomePage from "./Pages/HomePage";
import PortfoliosPage from "./Pages/PortfoliosPage";
import Publication from "./Pages/Publication";
import ResumePage from "./Pages/ResumePage";

function App() {
  const [theme, setTheme] = useState("dark-theme");
  // const [checked, setChecked] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const location = useLocation();
  const [count, setCount] = useState(0);
  // const TRACKING_ID = "UA-228170389-1";
  // ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    document.documentElement.className = theme;
  }, [theme]);

  // toggles when route changes
  useEffect(() => {
    // count to stop toggle for first loading
    if (count === 0) {
      setCount(count + 1);
    } else {
      setCount(count + 1);
      setNavToggle(!navToggle);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  // const themeToggler = () => {
  //   if (theme === "light-theme") {
  //     setTheme("dark-theme");
  //     setChecked(false);
  //   } else {
  //     setTheme("light-theme");
  //     setChecked(true);
  //   }
  // };

  // const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  //   width: 62,
  //   height: 34,
  //   padding: 7,
  //   "& .MuiSwitch-switchBase": {
  //     margin: 1,
  //     padding: 0,
  //     transform: "translateX(6px)",
  //     "&.Mui-checked": {
  //       color: "#fff",
  //       transform: "translateX(22px)",
  //       "& .MuiSwitch-thumb:before": {
  //         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //           "#fff"
  //         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
  //       },
  //       "& + .MuiSwitch-track": {
  //         opacity: 1,
  //         backgroundColor: theme === "dark" ? "#8796A5" : "#aab4be",
  //       },
  //     },
  //   },
  //   "& .MuiSwitch-thumb": {
  //     backgroundColor: theme === "dark" ? "#003892" : "#001e3c",
  //     width: 32,
  //     height: 32,
  //     "&:before": {
  //       content: "''",
  //       position: "absolute",
  //       width: "100%",
  //       height: "100%",
  //       left: 0,
  //       top: 0,
  //       backgroundRepeat: "no-repeat",
  //       backgroundPosition: "center",
  //       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
  //         "#fff"
  //       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
  //     },
  //   },
  //   "& .MuiSwitch-track": {
  //     opacity: 1,
  //     backgroundColor: theme === "dark" ? "#8796A5" : "#aab4be",
  //     borderRadius: 20 / 2,
  //   },
  // }));

  return (
    <div className="App">
      <Sidebar navToggle={navToggle} />

      {/* <div className="theme">
        <div
          className="light-dark-mode"
          style={{
            height: "5px",
            backgroundColor: "transparent",
          }}
        >
          {
            <MaterialUISwitch
              sx={{ m: 1 }}
              checked={checked}
              onClick={themeToggler}
            />
          } */}
      {/* <div className="left-content">
            <Brightness4Icon />
          </div>
          <div className="right-content">
            <Switch
              value=""
              checked={checked}
              inputProps={{ "aria-label": "" }}
              size="medium"
              onClick={themeToggler}
            />
          </div> */}
      {/* </div>
      </div> */}

      <div className="ham-burger-menu">
        <IconButton onClick={() => setNavToggle(!navToggle)}>
          <MenuIcon />
        </IconButton>
      </div>

      <MainContentStyled>
        {/* <div className="lines">
          <div className="line-1"></div>
          <div className="line-2"></div>
          <div className="line-3"></div>
          <div className="line-4"></div>
        </div> */}

        <Switching>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/about" exact>
            <AboutPage />
          </Route>
          <Route path="/resume" exact>
            <ResumePage />
          </Route>
          <Route path="/publications" exact>
            <Publication />
          </Route>
          <Route path="/work" exact>
            <PortfoliosPage />
          </Route>
          <Route path="/blogs" exact>
            <BlogsPage />
          </Route>
          <Route path="/contact" exact>
            <ContactPage />
          </Route>
        </Switching>
      </MainContentStyled>
    </div>
  );
}

const MainContentStyled = styled.main`
  position: relative;
  margin-left: 16.3rem;
  min-height: 100vh;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
  .lines {
    position: absolute;
    min-height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    opacity: 0.4;
    z-index: -1;
    .line-1,
    .line-2,
    .line-3,
    .line-4 {
      width: 1px;
      min-height: 100vh;
      background-color: var(--border-color);
    }
  }
`;

export default App;
