import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import ServiceCard from "../Components/ServiceCard";
import design from "../img/design.svg";
import intelligence from "../img/intelligence.svg";
import team from "../img/team.png";

function ServicesSection() {
  return (
    <InnerLayout>
      <ServicesSectionStyled>
        <Title title={"What I Do"} />
        <div className="services">
          <ServiceCard
            image={design}
            title={"Software Development"}
            paragraph={
              "Develop softwares ranging from SPAs to Enterprise software using various tech stacks."
            }
          />
          <div className="mid-card">
            <ServiceCard
              image={intelligence}
              title={"Artificial Intelligence"}
              paragraph={
                "Develop artificially intelligent applications using advanced artificial intelligence technologies. Collaborate in machine-learning research projects."
              }
            />
          </div>
          <ServiceCard
            image={team}
            title={"Team Management"}
            paragraph={
              "Guide team to successfully deliver projects within deadlines. Identify and encourage areas for growth and improvement within the team."
            }
          />
        </div>
      </ServicesSectionStyled>
    </InnerLayout>
  );
}

const ServicesSectionStyled = styled.section`
  .services {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default ServicesSection;
