import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import React from "react";
import styled from "styled-components";
import ResumeItem from "../Components/ResumeItem";
import SmallTitle from "../Components/SmallTitle";
import { InnerLayout } from "../styles/Layouts";

function Resume() {
  const briefcase = <BusinessCenterIcon />;
  const school = <SchoolIcon />;

  return (
    <ResumeStyled>
      <InnerLayout>
        <div className="small-title u-small-title-margin">
          <SmallTitle icon={briefcase} title={"Work Experience"} />
        </div>
        <div className="resume-content">
        <ResumeItem
            year={"August 2022 - Present"}
            title={"Graduate Research Assistant"}
            subTitle={"Kennesaw State University"}
            text={
              "\u2022 Conduct literature review and data analysis for deep learning and natural language processing projects. \n\u2022 Develop and implement deep learning and natural language processing models and algorithms. \n\u2022 Evaluate and optimize the performance and accuracy of the models and algorithms. \n\u2022 Write, present and review research papers and reports on the findings and results. \n\u2022 Collaborate with other researchers, faculty and students on related research topics. \n\u2022 Mentor undergraduate students on machine learning, deep learning and natural language processing concepts and techniques."
            }
          />
          <ResumeItem
            year={"February 2021 - July 2022"}
            title={"Software Engineer"}
            subTitle={"Bengal Mobile QA Solution"}
            text={
              "\u2022 Develop web applications using Angular, NestJS, ExpressJS, CassandraDB etc. \n\u2022 Involved in the analysis of business requirements and planning. \n\u2022 Assisting the senior developers with designs, developments and deployments."
            }
          />
          <ResumeItem
            year={"September 2020 - January 2021"}
            title={"Trainee Software Engineer"}
            subTitle={"ERA Infotech"}
            text={
              "\u2022 Developed multiple web applications for client and in-house use using Angular, React, Python,TypeScript, ExpressJS, MongoDB etc. \n\u2022 Upgraded aging code base with new source code."
            }
          />
          <ResumeItem
            year={"July 2020 - August 2020"}
            title={"Machine Learning Intern"}
            subTitle={"Mayalogy"}
            text={
              "\u2022 Performed Data clustering, Data Analysis and understood pattern of data. \n\u2022 Trained machine learning models. \n\u2022 Collected data and prepared datasets. "
            }
          />
          <ResumeItem
            year={"September 2019 - December 2019"}
            title={"Teaching Assistant (TA)"}
            subTitle={
              "Department of Computer Science & Engineering, BRAC University"
            }
            text={
              "\u2022 Provided students with out of class assistance and one-on-one tutoring. \n\u2022 Responsible for grading assignments and monitor lab tasks."
            }
          />
          <ResumeItem
            year={"June 2018 - June 2019"}
            title={"Assistant Director"}
            subTitle={"BRAC University Computer Club (BUCC), BRAC University"}
            text={
              "\u2022 Organized and managed several workshops and seminars."
            }
          />
        </div>
        <div className="small-title u-small-title-margin">
          <SmallTitle icon={school} title={"Educational Qualifications"} />
        </div>
        <div className="resume-content ">
        <ResumeItem
            year={"2022 - Present"}
            title={"Doctor of Philosophy (Ph.D.) in Computer Science"}
            subTitle={"Kennesaw State University"}
            text={""}
          />
          <ResumeItem
            year={"2016 - 2019"}
            title={"Bachelor of Science in Computer Science and Engineering"}
            subTitle={"BRAC University"}
            text={
              "\u2022 Grade : High Distinction \n\u2022 Awarded Dean's List and VC's List for outstanding result."
            }
          />
          <ResumeItem
            year={"2015"}
            title={"Higher Secondary Certificate"}
            subTitle={"Dhaka Residential Model College"}
            text={
              "\u2022 Grade : 5.00 / 5.00 \n\u2022 Group : Science (English Version)"
            }
          />
          <ResumeItem
            year={"2013"}
            title={"Secondary School Certificate"}
            subTitle={"Dhaka Residential Model College"}
            text={
              "\u2022 Grade : 5.00 / 5.00 \n\u2022 Group : Science (English Version) "
            }
          />
        </div>
      </InnerLayout>
    </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  .small-title {
    padding-bottom: 3rem;
  }
  .u-small-title-margin {
    margin-top: 4rem;
  }

  .resume-content {
    border-left: 2px solid var(--border-color);
  }
`;
export default Resume;
