import { LibraryBooks } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import ResumeItem from "../Components/ResumeItem";
import SmallTitle from "../Components/SmallTitle";
import { InnerLayout } from "../styles/Layouts";

function Resume() {
  const libray = <LibraryBooks />;

  return (
    <ResumeStyled>
      <InnerLayout>
        <div className="small-title u-small-title-margin">
          <SmallTitle icon={libray} title={"Publications"} />
        </div>
        <div className="resume-content ">
        <ResumeItem
            year={"January 2024"}
            title={
              "Domain-Enhanced Attention Enabled Deep Network for Behavioral Health Identification from 911 Narratives"
            }
            subTitle={
              "IEEE International Conference on Big Data (IEEE BigData)"
            }
            text={
              "Publisher : IEEE \nDigital Object Identifier (DOI) : 10.1109/BigData59044.2023.10386126"
            }
            link={"https://doi.org/10.1109/BigData59044.2023.10386126"}
          />
        <ResumeItem
            year={"August 2023"}
            title={
              "Sentence Level Analysis for Detecting Mental Health Causes Using Social Media Posts"
            }
            subTitle={
              "Computer Software and Applications Conference (COMPSAC)"
            }
            text={
              "Publisher : IEEE \nDigital Object Identifier (DOI) : 10.1109/COMPSAC57700.2023.00211"
            }
            link={"https://doi.org/10.1109/COMPSAC57700.2023.00211"}
          />
        <ResumeItem
            year={"August 2023"}
            title={
              "Post-stroke Exercise Assessment using Hybrid Quantum Neural Network"
            }
            subTitle={
              "Computer Software and Applications Conference (COMPSAC)"
            }
            text={
              "Publisher : IEEE \nDigital Object Identifier (DOI) : 10.1109/COMPSAC57700.2023.00078"
            }
            link={"https://doi.org/10.1109/COMPSAC57700.2023.00078"}
          />
        <ResumeItem
            year={"August 2023"}
            title={
              "CMTN: A Convolutional Multi-Level Transformer to Identify Suicidal Behaviors Using Clinical Notes"
            }
            subTitle={
              "Computer Software and Applications Conference (COMPSAC)"
            }
            text={
              "Publisher : IEEE \nDigital Object Identifier (DOI) : 10.1109/COMPSAC57700.2023.00234"
            }
            link={"https://doi.org/10.1109/COMPSAC57700.2023.00234"}
          />
        <ResumeItem
            year={"June 2023"}
            title={
              "Detecting Motor Imagery Movement from EEG Signal"
            }
            subTitle={
              "ACM Southeast Conference (ACM-SE)"
            }
            text={
              "Publisher : ACM \nDigital Object Identifier (DOI) : 10.1145/3564746.3587009"
            }
            link={"https://doi.org/10.1145/3564746.3587009"}
          />
          <ResumeItem
            year={"April 2021"}
            title={
              "Bus Management & Road Accident Prevention System for Smart Cities "
            }
            subTitle={
              "International Conference on Computer and Information Technology (ICCIT)"
            }
            text={
              "Publisher : IEEE \nDigital Object Identifier (DOI) : 10.1109/ICCIT51783.2020.9392747"
            }
            link={"https://doi.org/10.1109/ICCIT51783.2020.9392747"}
          />
          <ResumeItem
            year={"Volume 11 Issue 1 January, 2020"}
            title={
              "Performance Analysis of Machine Learning Classifiers for Detecting PE Malware"
            }
            subTitle={
              "International Journal of Advanced Computer Science and Applications (IJACSA)"
            }
            text={
              "Web of Science, Scopus, Inspec, Ebsco, Microsoft Academic, WorldCat indexed \nDigital Object Identifier (DOI) : 10.14569/IJACSA.2020.0110163"
            }
            link={"https://doi.org/10.14569/IJACSA.2020.0110163"}
          />
        </div>
      </InnerLayout>
    </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  .small-title {
    padding-bottom: 3rem;
  }
  .u-small-title-margin {
    margin-top: 4rem;
  }

  .resume-content {
    border-left: 2px solid var(--border-color);
  }
`;
export default Resume;
