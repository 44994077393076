import GithubIcon from "@material-ui/icons/GitHub";
import LogoDevIcon from '@mui/icons-material/LogoDev';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Scholar from '../img/scholar.svg'
import React from "react";
import styled from "styled-components";

function HomePage() {
  return (
    <HomePageStyled>
      {/* <div className="particle-con">
        <Particle />
      </div> */}
      <div className="typography">
        <h1>
          Hi, I'm <span>Adnan</span>
        </h1>
        <p>
          I am a software engineer and machine-learning enthusiast. I am
          passionate about technologies and love to learn new things.
        </p>
        <div className="icons">
          <a target="_blank" href="https://github.com/adnanazmee" className="icon i-github" rel="noreferrer">
            <GithubIcon />
          </a>
          <a target="_blank" href="https://dev.to/adnanazmee" className="icon i-dev" rel="noreferrer">
            <LogoDevIcon />
          </a>
          <a target="_blank" href="https://scholar.google.com/citations?user=x7GrKoMAAAAJ&hl=en" className="icon i-scholar" rel="noreferrer">
            <img src={Scholar} alt="" style={{width:"2.5rem"}}/>
          </a>
          <a target="_blank" href="https://www.linkedin.com/in/adnanazmee/" className="icon i-linkedin" rel="noreferrer">
          <LinkedInIcon />
          </a>
        </div>
      </div>
    </HomePageStyled>
  );
}

const HomePageStyled = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;

  .typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;

    .icons {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      .icon {
        border: 2px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        &:hover {
          border: 2px solid var(--primary-color);
          color: var(--primary-color);
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
        svg {
          margin: 0.5rem;
        }
      }
      
      .i-linkedin {
        &:hover {
          border: 2px solid #0B67C3;
          color: #0B67C3;
        }
      }
      .i-dev {
        &:hover {
          border: 2px solid blue;
          color: blue;
        }
      }
      .i-scholar {
        &:hover {
          border: 2px solid ;
          color: ;
        }
      }
      .i-github {
        &:hover {
          border: 2px solid #5f4687;
          color: #5f4687;
        }
      }
    }
  }
`;

export default HomePage;
