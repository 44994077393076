import dhs from "../img/portImages/dhs_motors.png";
import ums from "../img/portImages/ums.png";
import ava from "../img/portImages/ava_2.png";
import weather from "../img/portImages/weather_app.png";
import store from "../img/portImages/store.png";
import doctor from "../img/portImages/doctor.jpg";
import iot from "../img/portImages/iot.jpg";
import port from "../img/portImages/personal_port.png";
import ml from "../img/machine_learning.jpeg";
import jogajog from "../img/portImages/con.png";
import brac from "../img/portImages/brac.png";

const portfolios = [
  {
    id: 13,
    category: "MEAN",
    image: brac,
    link1:
      "https://blog.brac.net/data-for-good-doing-development-with-digitalisation/",
    title: "BRAC UPG",
    text: "Developed web applications to digitalize Ultra Poor Graduation (UPG), 2nd largest programme of BRAC NGO.",
  },
  {
    id: 12,
    category: "MEAN",
    image: jogajog,
    title: "Jogajog",
    text: "A cross platform telecommunication application for messaging, videoconferencing and voice calls.",
  },
  {
    id: 1,
    category: "MEAN",
    image: dhs,
    link1: "http://hondaconnect.erainfotechbd.com:9852/",
    title: "Honda Connect",
    text: "Enterprise resource planning (ERP) software developed for DHS Motors using MEAN stack.",
  },
  {
    id: 2,
    category: "Machine Learning",
    image: ava,
    link1: "http://www.bankasia-bd.com/",
    title: "AVA Chatbot",
    text: "An artificially intelligent chat-bot for Bank Asia, Bangladesh.      Technology Used: React JS, Flask etc.",
  },
  {
    id: 3,
    category: "MEAN",
    image: ums,
    link1: "http://hondaconnect.erainfotechbd.com:9851/",
    title: "UMS",
    text: "Developed an user management system using MEAN stack.",
  },
  {
    id: 4,
    category: "Django",
    image: store,
    link2: "https://github.com/adnanazmee/e-store",
    title: "E-Store",
    text: "Developed an e-commerce website using Django framework.",
  },
  {
    id: 5,
    category: "Django",
    image: port,
    title: "Personal Portfolio",
    text: "Personal portfolio website developed using Django framework.",
  },
  {
    id: 6,
    category: "React",
    image: weather,
    link2: "https://github.com/adnanazmee/Weather-forecast-app-react",
    title: "Weather Forecaster",
    text: "Developed a weather forecast application using React JS.",
  },
  {
    id: 7,
    image: doctor,
    category: "PHP",
    link2: "https://github.com/adnanazmee/Dr-Dhaka",
    title: "Find Your Doctor",
    text: "Developed a website to help people find their preferred doctor and take appointment at suitable time.",
  },
  {
    id: 8,
    image: ml,
    category: "Machine Learning",
    title: "Sentiment Analysis Social Media",
    text: "Performed sentiment analysis on data collected from social media using neural networks.",
  },
  {
    id: 9,
    image: ml,
    category: "Machine Learning",
    link2:
      "https://github.com/adnanazmee/Malicious-website-detection-using-machine-learning",
    title: "Malicious Website Detector",
    text: "Detected malicious websites using machine learning algorithms.",
  },
  {
    id: 10,
    category: "IoT",
    image: iot,
    title: "Emergency response system",
    text: "Developed a system that helps elderly people who live alone to get help in case of emergency using Arduino, gyroscope, PIR sensor etc.",
  },
  {
    id: 11,
    category: "IoT",
    image: iot,
    title: "Smart Cane",
    text: "Built a smart cane using Arduino to help blind people’s movement.",
  },
];

export default portfolios;
